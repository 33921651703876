@use '@app/styles/colors';
@use '@app/styles/mixins';

.loyality-barcode {
  color: colors.$main-inverted-text-color;
  background-color: colors.$deep-inverted-bg-color;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
    justify-content: center;

    padding: 2.5rem 0;

    text-align: center;
  }

  &__title {
    @include mixins.font(clamp(1.125rem, 1.67vw, 2rem));
  }

  &__description {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));
  }

  &__placeholder {
    width: 100%;
    height: fit-content;
  }

  &__btn.button {
    width: 100%;
    background-color: transparent;
    border-color: colors.$main-inverted-border-color;
  }

  #barcode-scanner-placeholder__dashboard {
    padding-inline: 1em;
  }
}
