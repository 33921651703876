@use '@app/styles/mixins';

.event-card {
  @include mixins.card;

  width: var(--event-card-size);

  &__bg {
    aspect-ratio: 1 / 1;
    width: var(--event-card-size);
  }

  &__content {
    @include mixins.font(clamp(1.5rem, 2.083vw, 2.5rem), Tsvetnoy-Display);

    justify-content: space-between;
    padding: clamp(1.5rem, 2.5vw, 3rem);
  }

  &__details {
    display: flex;
    gap: 2ch;
  }
}
