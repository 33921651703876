@use '@app/styles/mixins.scss';
@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';

.service-card {
  @include mixins.card;

  position: relative;
  max-width: 570px;

  &__image {
    aspect-ratio: 3 / 2;
    width: 100%;

    &--dummy {
      height: unset;
    }
  }

  &__caption {
    margin-top: clamp(0.6rem, 1.042vw, 1.25rem);
    font-size: clamp(1rem, 1.25vw, 1.5rem);
    font-weight: 500;
  }
}
