.markdown {
  h2 {
    display: block;
    margin-bottom: 1em;
    font-size: 1.2em;
    font-weight: bold;
  }

  h3 {
    display: block;
    margin-bottom: 1em;
    font-weight: bold;
  }

  ul {
    list-style: circle outside;
  }

  ol {
    list-style: decimal outside;
  }

  ol,
  ul {
    margin-left: 1em;
  }

  p,
  ul,
  ol {
    margin-block: 1em;

    &:last-child {
      margin-block: 1em 0;
    }

    &:first-child {
      margin-block: 0 1em;
    }
  }

  a {
    font-weight: 500;
    text-decoration-line: underline;
  }

  strong {
    font-weight: bold;
  }

  li + li {
    margin-top: 1em;
  }

  table {
    border: 1px solid #ddd;

    th, td {
      padding: 0.5em;
      border: 1px solid #ddd;
    }

    td {
      background-color: #f9f9f9;
    }
  }
}
