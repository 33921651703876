@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.loyality-services {
  &__title {
    @include mixins.font(clamp(1.125rem, 1.67vw, 2rem));

    margin-bottom: 1.25em;
  }

  &__swiper.swiper {
    overflow-y: visible;

    @media #{vars.$not-mobile} {
      margin-right: calc(vars.$page-inline-padding * -1);
      padding-right: vars.$page-inline-padding;
    }

    @media #{vars.$mobile} {
      @include mixins.full-width;
    }
  }

  &__card.swiper-slide {
    cursor: pointer;
    user-select: none;
    width: clamp(237px, 22vw, 420px);
  }

  &__card {
    &--unavailable {
      opacity: 0.2;
    }
  }

  &__image {
    aspect-ratio: 14 / 9;
    margin-bottom: clamp(0.75rem, 1.05vw, 1.25rem);
  }

  &__popover {
    display: flex;
    flex-direction: column;

    width: max(400px, 30vw);

    background-repeat: no-repeat;
    background-position: left bottom;

    @media #{vars.$mobile} {
      width: 100%;
    }
  }

  &__popover-title {
    @include mixins.font(clamp(1.125rem, 1.67vw, 2rem));

    margin-bottom: 1.25em;
  }

  &__popover-description {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    margin-bottom: 1.25em;
  }
}
