@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.loyality-tab {
  min-width: 0;

  &__title-group {
    margin-bottom: clamp(1.5rem, 1.875vw, 2.25rem);
  }

  &__title {
    @include mixins.section-title;

    margin-bottom: clamp(1.25rem, 1.25vw, 1.5rem);
  }

  &__description {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    a {
      text-decoration: underline;
    }
  }

  &__swiper.swiper {
    padding-block: clamp(1rem, 1.25vw, 1.5rem);

    @media #{vars.$not-mobile} {
      margin-right: calc(vars.$page-inline-padding * -1);
      padding-right: vars.$page-inline-padding;
    }

    @media #{vars.$mobile} {
      @include mixins.full-width;
    }
  }

  &__card.swiper-slide {
    cursor: pointer;
    user-select: none;
    width: fit-content;
  }

  &__card {
    &--disabled {
      opacity: 0.2;
    }
  }

  &__progress-bar {
    margin-block: clamp(0.75rem, 0.833vw, 1rem) clamp(1.75rem, 2.083vw, 2.5rem);
  }

  &__wallets {
    margin-block: clamp(1.75rem, 2.083vw, 2.5rem) clamp(2.5rem, 4.167vw, 5rem);

    @media #{vars.$mobile} {
      @include mixins.full-width();
    }
  }

  &__privileges {
    margin-top: clamp(1.75rem, 2.083vw, 2.5rem);

    @media #{vars.$not-mobile} {
      margin-bottom: clamp(2.5rem, 4.167vw, 5rem);
    }

    &-content {
      @media #{vars.$mobile} {
        margin-bottom: clamp(2.5rem, 4.167vw, 5rem);
      }
    }
  }

  &__discounts-hint {
    @include mixins.full-width;
    @include mixins.font(1rem);

    margin-block: 20px;
    padding: vars.$page-inline-padding;
    background-color: colors.$faint-bg-color;
    border-radius: 0.75em;

    a {
      text-decoration-line: underline;
    }
  }

  &__scan-barcode {
    @include mixins.full-width;

    margin-bottom: clamp(2.5rem, 4.167vw, 5rem);
  }

  &__services {
    @media #{vars.$not-mobile} {
      margin-top: clamp(2.5rem, 4.167vw, 5rem);
    }
  }
}
