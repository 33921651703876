@use '@app/styles/colors';

.resend-code {
  font-weight: 500;

  &__label {
    color: colors.$faint-text-color;

    &--error {
      color: colors.$error-color;
    }
  }

  &__btn {
    text-decoration-line: underline;
  }
}
