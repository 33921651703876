@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.loyality-brand {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1em;
  align-content: space-between;
  justify-content: space-between;

  aspect-ratio: 7 / 4;
  width: clamp(240px, 22vw, 420px);
  max-height: 100%;
  padding: clamp(0.75rem, 1.9vw, 2.25rem);

  background-position: center;
  background-size: cover;
  border: 1px solid colors.$faint-border-color;
  border-radius: clamp(0.75rem, 1.04vw, 1.25rem);

  &__name {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));
  }

  &__discount {
    @include mixins.font(clamp(1.5rem, 2.5vw, 3rem), Tsvetnoy-Display);

    margin-top: auto;
  }

  &__logo {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1 / 1;
    width: clamp(52px, 4.17vw, 80px);

    background-color: colors.$main-bg-color;
    border-radius: clamp(12px, 1.25vw, 24px);
  }

  &__logo-image {
    width: clamp(52px, 4.17vw, 80px);
  }

  @media #{vars.$mobile} {
    width: 100%;
  }
}
