@use '@app/styles/vars';
@use '@app/styles/mixins';

.slider-layout {
  @include mixins.full-width();

  &__swiper.swiper {
    overflow: visible;
  }

  &:is(&--squares) {
    .slider-layout__card {
      width: clamp(328px, 30vw, 570px);

      &--stripe {
        width: clamp(156px, 22vw, 420px);
      }
    }

    .slider-layout__card-picture {
      aspect-ratio: 1 / 1;
    }
  }

  &:is(&--striped) {
    .swiper-wrapper {
      align-items: flex-end;
    }
  }

  &:is(&--fixed-height) {
    .slider-layout__card-picture {
      height: clamp(328px, 30vw, 570px);
    }
  }

  &__slide {
    width: fit-content;
  }

  &__card-picture {
    @include mixins.image-common;

    max-width: calc(100vw - vars.$page-inline-padding * 2);
  }
}
