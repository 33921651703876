@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.wallets {
  @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;

  padding: clamp(0.75rem, 1.04vw, 1.25rem);

  background-color: colors.$secondary-bg-color;
  border-radius: 1.5rem;

  @media #{vars.$mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding-inline: vars.$page-inline-padding;
  }

  &__description {
    &--error {
      color: colors.$error-color;
    }
  }

  &__buttons {
    display: flex;
    gap: 1.33em;
    align-items: center;
    justify-content: center;

    @media #{vars.$mobile} {
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__button {
    cursor: pointer;

    flex-basis: 50%;

    min-width: 120px;
    max-width: 210px;

    transition-duration: 0.3s;

    &:hover {
      transform: scale(1.2);
    }
  }
}
