@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.scanned-product {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;

  width: 100%;

  &__brand {
    @include mixins.font(0.875rem);

    margin-bottom: 0.5rem;
    color: colors.$main-inverted-text-color;
  }

  &__title {
    @include mixins.font(1.5rem, Tsvetnoy-Display);

    margin-bottom: 0.25rem;
    color: colors.$main-inverted-text-color;
  }

  &__params,
  &__original-price {
    @include mixins.font(0.875rem);

    color: colors.$tp-inverted-text-color;
  }

  &__params {
    margin-bottom: 1rem;
    text-align: start;
  }

  &__original-price,
  &__price {
    display: flex;
    gap: 1ch;
    align-items: baseline;
    align-self: flex-end;
  }

  &__price {
    @include mixins.font(1rem);

    color: colors.$main-inverted-text-color;
  }

  &__price-value {
    @include mixins.font(1.5rem, Tsvetnoy-Display);
  }
}
