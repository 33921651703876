@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.my-events-tab {
  min-width: 0;
  margin-bottom: clamp(2.5rem, 7.3vw, 8.75rem);

  &__title {
    @include mixins.section-title;

    margin-bottom: clamp(1.75rem, 4.17vw, 5rem);
  }

  &__subtitle {
    @include mixins.font(clamp(1.125rem, 1.667vw, 2rem), $weight: 500);
  }

  &__description {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem), Tsvetnoy-Book);
  }

  &__events-link {
    width: min(500px, 26vw);

    @media #{vars.$mobile} {
      width: 100%;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: clamp(1.25rem, 2.1vw, 2.5rem);
    margin-bottom: clamp(3.75rem, 5.2vw, 6.25rem);
  }

  &__slider {
    @media #{vars.$not-mobile} {
      margin-right: calc(vars.$page-inline-padding * -1);
      padding-right: vars.$page-inline-padding;
    }

    @media #{vars.$mobile} {
      @include mixins.full-width;
    }
  }

  &__card {
    width: clamp(240px, 22vw, 420px);

    &--inactive {
      opacity: 0.4;
    }
  }
}
