@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.brands-tab {
  width: 100%;

  &__title {
    @include mixins.section-title;

    margin-bottom: clamp(1.75rem, 4.17vw, 5rem);
  }

  &__progress-bar {
    margin-bottom: clamp(1.75rem, 3.125vw, 3.75rem);
  }

  &__controls {
    display: flex;
    gap: 28px;
    align-items: baseline;
    justify-content: space-between;

    margin-bottom: clamp(2.5rem, 4.17vw, 5rem);

    @media #{vars.$not-mobile} {
      width: calc(clamp(240px, 22vw, 420px) * 2 + clamp(1.25rem, 2.1vw, 2.5rem));
    }

    @media #{vars.$mobile} {
      flex-direction: column;
    }
  }

  &__search {
    @media #{vars.$not-mobile} {
      width: clamp(240px, 22vw, 420px);
    }

    @media #{vars.$mobile} {
      width: 100%;
    }
  }

  &__sort-type {
    display: flex;
    gap: clamp(1rem, 1.6vw, 1.875rem);
  }

  &__sort-btn {
    padding: clamp(0.5rem, 0.63vw, 0.75rem) clamp(0.75rem, 0.85vw, 1rem);

    &--active {
      color: colors.$main-inverted-text-color;
      background-color: colors.$main-inverted-bg-color;
    }
  }

  &__brands-group {
    display: flex;
    flex-direction: column;
    gap: clamp(1.75rem, 3.125vw, 3.75rem);
    margin-bottom: clamp(40px, 5.2vw, 100px);
  }

  &__brands {
    display: grid;
    gap: clamp(1.25rem, 2.1vw, 2.5rem);

    @media #{vars.$not-mobile} {
      grid-template-columns: 1fr 1fr;
      align-self: self-start;
    }

    @media #{vars.$mobile} {
      grid-template-columns: 1fr;
    }
  }

  .swiper-slide {
    width: fit-content;
  }

}
