@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.auth {
  @include mixins.page-spacing();

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(45deg, rgb(255 255 255 / 0%) 0%, colors.$main-bg-color 30%), radial-gradient(circle at 6% 53%, rgb(255 255 0 / 56%) 6%, rgb(255 144 244 / 54%) 12%, colors.$main-bg-color 18%);

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @media #{vars.$not-mobile} {
      width: 420px;
    }

    @media #{vars.$mobile} {
      width: 100%;
    }
  }

  &__abort-btn {
    position: absolute;
    inset: calc(clamp(1rem, 3.125vw, 3.75rem) + vars.$header-height) auto auto vars.$page-inline-padding;

    @media #{vars.$mobile} {
      inset: calc(clamp(1rem, 3.125vw, 3.75rem) + vars.$header-height) vars.$page-inline-padding auto auto;
    }
  }

  &__title-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    @include mixins.font(clamp(1.125rem, 1.67vw, 2rem));
  }

  &__description {
    @include mixins.font(clamp(0.875rem, 0.85vw, 1rem));
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    .text-input {
      @include mixins.font(clamp(1rem, 1.04vw, 1.25rem));
    }

  }

  &__label {
    display: flex;
    flex-direction: column;
  }

  &__mandatory-fields-hint {
    @include mixins.font(clamp(0.75rem, 0.833vw, 1rem), $weight: 500, $line-height: 2);

    color: colors.$faint-text-color;
  }

  &__submit-btn {
    width: 100%;
  }

  &__accept-policy {
    @include mixins.font(1rem);

    color: colors.$faint-text-color;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: clamp(1rem, 1.25vw, 1.5rem);
  }

  &__error {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    margin-block: 1.5em 0.5em;
    color: colors.$error-color;
  }

}
