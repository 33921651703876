@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.account-page {

  @include mixins.page-spacing;

  display: flex;
  gap: clamp(2.5rem, 9vw, 10.75rem);

  &:has(.personal-info-tab) {
    background: linear-gradient(45deg, rgb(255 255 255 / 0%) 0%, colors.$main-bg-color 30%), radial-gradient(circle at 6% 53%, rgb(255 255 0 / 56%) 6%, rgb(255 144 244 / 54%) 12%, colors.$main-bg-color 18%);
  }

  &__tabs {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    display: flex;
    flex-direction: column;
    gap: clamp(1em, 1.458vw, 1.17rem);
    white-space: nowrap;

    @media #{vars.$mobile} {
      @include mixins.full-width;
      @include mixins.inline-slider;
    }
  }

  &__tab {
    @media #{vars.$mobile} {
      &:not(.active) {
        color: colors.$pale-text-color;
      }
    }
  }

  &__tab.active {
    text-decoration: underline;
  }

  @media #{vars.$not-mobile} {

  }

  @media #{vars.$mobile} {
    flex-direction: column;
    gap: 1.35em;
  }
}
