.not-available {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        width: 5248px;
        height: 5248px;
        background-size: 5248px 5248px;
        background-image: url("~@public/img/not-available-bg.png");
        background-repeat: no-repeat;
        animation: 15s linear infinite bg_animation;
        z-index: -1;
    }

    &__icon {
        width: clamp(70px, 7vw, 95px);
        height: auto;
    }

    &__title {
        font-family: Tsvetnoy-Display;
        font-size: clamp(28px, 4vw, 48px);
        margin: 0 16px;
    }

    &__text {
        font-size: clamp(16px, 3vw, 24px);
        margin: 0 16px;
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: clamp(20px, 5vw, 48px);
        text-align: center;
        height: 100%;
        width: 100%;
        background-image: url("~@public/img/not-available-text-bg.png");
        background-size: 100vw 100vh;
        background-position: center;
        background-repeat: no-repeat;
    }
}

@media (max-width: 500px) {
    .not-available__container {
        background-image: url("~@public/img/not-available-text-bg-mobile.png");
    }
}


@keyframes bg_animation {
    0% {
        transform: rotate(0);
        left: -1117px;
        top: -3108px;
    }

    33% {
        transform: rotate(51deg);
        left: -2474px;
        top: -2084px;
    }

    66% {
        transform: rotate(227deg);
        left: -2930px;
        top: -2222px;
    }

    100% {
        transform: rotate(360deg);
        left: -1117px;
        top: -3108px;
    }
}