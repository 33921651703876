@use "@app/styles/vars";
@use "@app/styles/colors";
@use "@app/styles/mixins";

.loyality-card {
  position: relative;
  transform-style: preserve-3d;

  display: flex;

  aspect-ratio: 285 / 178;
  width: clamp(310px, 30vw, 570px);
  height: 100%;

  perspective: 1500px;

  transition: all 750ms ease-out;

  &__front,
  &__back {
    position: absolute;
    inset: 0;

    padding: clamp(1rem, 1.25vw, 1.5rem);

    backface-visibility: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: clamp(0.5rem, 1.25vw, 1.5rem);
  }

  &__front {
    z-index: 1;
    display: grid;
    grid-template-columns: auto auto;
    flex-basis: 100%;
  }

  &__back {
    transform: rotateY(180deg);
    display: grid;
    grid-template-columns: auto auto;

    &::after {
      content: "";

      position: absolute;
      z-index: -1;
      inset: 0;

      opacity: 0.4;
      background-color: colors.$main-bg-color;
    }
  }

  &__logo {
    width: clamp(23px, 2.3vw, 44px);
    height: auto;
  }

  &__barcode {
    grid-column: 1 / 3;
    align-self: end;
    padding-inline: 1rem;
  }

  &__title-group,
  &__details {
    display: flex;
    flex-direction: column;

    @media #{vars.$mobile} {
      gap: 0.5rem;
    }
  }

  &__promo {
    @include mixins.font(clamp(0.67rem, 0.84vw, 1rem), Tsvetnoy-Book);

    display: inline-flex;
    gap: 0.5em;
    align-items: center;
  }

  &__tooltip {
    --rt-opacity: 1;

    width: clamp(120px, 12.5vw, 240px);
  }

  &__details {
    grid-column: 2;
    align-items: flex-end;
    align-self: self-end;
    justify-self: end;
  }

  &__turn-btn.button {
    $height: calc(clamp(0.875rem, 1.05vw, 1.25rem) * 1.2);

    @include mixins.font(clamp(0.67rem, 0.85vw, 1rem));

    align-self: start;
    justify-self: end;

    height: $height;
    padding: 0;

    text-align: end;
    text-decoration: underline;
    white-space: nowrap;

    background-color: transparent !important;
    border: 0;

    /* stylelint-disable-next-line order/order */
    --arrow-icon-size: #{$height};
  }

  &__title {
    @include mixins.font(min(clamp(2.25rem, 5vw, 6rem), 8vh), Tsvetnoy-Display);
  }

  &__label {
    @include mixins.font(clamp(0.875rem, 1.05vw, 1.25rem));
  }

  &__price {
    @include mixins.font(clamp(1.5rem, 2.5vw, 3rem), Tsvetnoy-Display);

    white-space: nowrap;
  }
}
