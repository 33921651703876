@use '@app/styles/colors';
@use '@app/styles/mixins';

.loyality-progress {
  $gradient: linear-gradient(90deg, #f7b63d 0%, #f19833 44%, #687cf2 100%);

  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;


  &__point {
    font-size: clamp(1rem, 1.25vw, 1.5rem);
    line-height: 1;
    opacity: 0.3;

    &--active {
      color: transparent;
      opacity: 1;
      background-image: $gradient;
      background-clip: text;
    }
  }

  &__skeleton {
    position: relative;
    flex: 0 1 50%;
    height: 1px;
    background-color: colors.$pale-bg-color;
  }

  &__progress {
    position: absolute;
    inset: 0;

    height: 2px;

    background-image: $gradient;

    transition: right 0.3s;
  }
}
