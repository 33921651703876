@use '@app/styles/vars';

.accordion {
  width: 100%;
}

.accordion-item {
  list-style-type: none;
}


.accordion-header {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;


  &__icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    transition-duration: 0.5s;

    &--flipped {
      rotate: 180deg;
    }

    @media #{vars.$mobile} {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
}

.accordion-content {
  overflow: hidden;
  height: 0;
}
