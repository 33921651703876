@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

@mixin icon-width($size: clamp(24px, 2.1vw, 40px)) {
  width: $size;
  min-width: $size;
  height: $size;
}

.brands-search {
  position: relative;
  display: flex;
  align-items: baseline;

  &__input {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    width: 100%;
    border-color: colors.$dim-text-color;
    transition-duration: 0.3s;

    &::placeholder {
      color: colors.$dim-text-color;
    }
  }

  &__search-icon,
  &__reset-icon {
    @include icon-width;

    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;

    opacity: 0;

    transition-duration: 0.3s;

    &--active {
      opacity: 1;
    }

    @media #{vars.$mobile} {
      @include icon-width(24px);
    }
  }
}
