@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.my-event-card {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 1.25vw, 1.5rem);

  &__unregister-btn {
    @include mixins.font(clamp(0.875rem, 0.84vw, 1rem));

    padding-block: 0;
  }
}
