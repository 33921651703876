@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.loyality-privileges {

  @media #{vars.$mobile} {
    @include mixins.full-width();

    border-top: solid 1px colors.$main-text-color;
  }

  &__title {
    @include mixins.font(clamp(1.125rem, 1.67vw, 2rem));

    @media #{vars.$not-mobile} {
      margin-bottom: 1.25em;
    }

    @media #{vars.$mobile} {
      margin-block: 1em;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: clamp(1rem, 1.25vw, 1.5rem);
  }

  &__group {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    gap: clamp(1rem, 1.25vw, 1.5rem);

    &--disabled {
      opacity: 0.2;
    }
  }

  &__item {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    display: flex;
    gap: clamp(0.75rem, 0.833vw, 1rem);
    align-items: baseline;
  }

  &__bullet {
    flex: 0 0 auto;

    aspect-ratio: 1 / 1;
    width: 0.8em;

    background-image: linear-gradient(100deg,
    #f7b63e 4%,
    #f09c2c 37%,
    #e2f37a 100%);
    border-radius: 50%;
  }
}
