@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/styles/mixins';

.personal-info-tab {
  display: flex;
  flex-direction: column;
  gap: clamp(2.25rem, 3.125vw, 3.75rem);
  width: max(22vw, 420px);

  @media #{vars.$mobile} {
    width: 100%;
  }

  &__title {
    @include mixins.section-title;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: clamp(1rem, 1.25vw, 1.5rem);
  }

  &__label {
    @include mixins.font(clamp(0.875rem, 0.84vw, 1rem));

    display: flex;
    flex-direction: column;

    .text-input {
      @include mixins.font(clamp(1rem, 1.05vw, 1.25rem));
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-top: clamp(2.25rem, 3.125vw, 3.75rem);
  }

  &__logout-btn.button {
    @include mixins.font(1rem);

    padding-block: 0;
  }

  &__edit-btn.button {
    padding-block: 0;
  }

  &__error {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));

    margin-block: 1.5em 0.5em;
    color: colors.$error-color;
  }
}
