@use '@app/styles/vars';
@use '@app/styles/colors';

.feedback {
  $common-spacing: clamp(1.25rem, 1.5vw, 1.75rem);

  min-width: 30vw;
  max-width: 950px;
  white-space: pre-wrap;

  &__title {
    margin-bottom: 1em;
    font-size: clamp(1.25rem, 1.67vw, 2rem);
  }

  &__details {
    margin-bottom: 1.83em;
    font-size: clamp(1rem, 0.83vw, 1.5rem);
  }

  &__common-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $common-spacing clamp(1.25rem, 1.563vw, 1.875rem);
    margin-bottom: $common-spacing;

    @media #{vars.$mobile} {
      grid-template-columns: auto;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;

    @media #{vars.$mobile} {
      width: 100%;
    }
  }

  &__comment {
    height: 7em;
    margin-bottom: $common-spacing;
  }

  &__accept-policy {
    margin-bottom: $common-spacing;
    font-size: clamp(0.75rem, 0.833vw, 1rem);
    color: colors.$faint-text-color;
  }

  &__submit-btn {
    width: 100%;
  }
}
